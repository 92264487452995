import { validatePhone } from '@utils/formValidation/phoneValidation';
import { validateCNPJ } from '@utils/validateCnpj';
import { validateCPF } from '@utils/validateCpf';
import { z } from 'zod';

export interface AuthResponse {
  token: string;
}

export const signInCredentialsSchema = z.object({
  document: z.preprocess(
    (value) => String(value).replace(/\D/g, ''),
    z
      .string()
      .trim()
      .nonempty({
        message: 'O documento é obrigatório',
      })
      .refine(
        (value) => {
          if (value.length === 11) return validateCPF(value);
          if (value.length === 14) return validateCNPJ(value);
          return false;
        },
        { message: 'O formato do documento é inválido' },
      ),
  ),
  password: z.string().trim().nonempty({
    message: 'A senha é obrigatória!',
  }),
});
export type SignInCredentials = z.infer<typeof signInCredentialsSchema>;

export const resetPasswordSchema = signInCredentialsSchema.pick({
  document: true,
});
export type ResetPasswordInput = z.infer<typeof resetPasswordSchema>;

const registerSchema = z.object({
  name: z.string().trim().nonempty({
    message: 'O nome é obrigatório',
  }),
  email: z
    .string()
    .trim()
    .nonempty({
      message: 'O email é obrigatório',
    })
    .email({
      message: 'O formato do email é invalido',
    }),
  phone: validatePhone(),
  termsOfUse: z
    .boolean()
    .refine((value) => value === true, {
      message: 'Você deve aceitar os termos de uso para continuar',
    })
    .default(false),
  birthDate: z
    .date({
      errorMap: (issue) => {
        switch (issue.code) {
          case 'invalid_type':
            return { message: 'A data de nascimento é obrigatória' };
          case 'invalid_date':
            return { message: 'Formato da data é inválido' };
          default:
            return { message: 'Verifique este campo' };
        }
      },
    })
    .refine(
      (value) => {
        const valueDate = new Date(value);
        const now = new Date();

        return now.getTime() - valueDate.getTime() >= 568025136000;
      },
      { message: 'Você deve ser maior de 18 anos para se cadastrar.' },
    )
    .optional(),
  document: z.preprocess(
    (value) => String(value).replace(/\D/g, ''),
    z
      .string()
      .trim()
      .nonempty({
        message: 'O documento é obrigatório',
      })
      .refine(
        (value) => {
          if (value.length === 11) return validateCPF(value);
          if (value.length === 14) return validateCNPJ(value);
          return false;
        },
        { message: 'O formato do documento é inválido' },
      ),
  ),
  password: z.string().trim().nonempty({
    message: 'A senha é obrigatória',
  }),
  passwordConfirmation: z.string().trim().nonempty({
    message: 'A confirmação de senha é obrigatória',
  }),
});
export type UserRegisterDto = z.infer<typeof registerSchema> & {
  referrerCode: string | null;
};

export const userInfoSchema = registerSchema.pick({
  name: true,
  email: true,
  phone: true,
  termsOfUse: true,
});
export type UserInfo = z.infer<typeof userInfoSchema>;

export const documentInfoSchema = registerSchema
  .pick({
    document: true,
    birthDate: true,
  })
  .refine((data) => !(data.document.length <= 11 && !data.birthDate), {
    message: 'A data de nascimento é obrigatória',
    path: ['birthDate'],
  });
export type DocumentInfo = z.infer<typeof documentInfoSchema>;

export const choosePasswordSchema = registerSchema
  .pick({
    password: true,
    passwordConfirmation: true,
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: 'As senhas não convergem',
    path: ['passwordConfirmation'], // path of error
  });
export type ChoosePassword = z.infer<typeof choosePasswordSchema>;

export const updatePasswordSchema = registerSchema
  .pick({
    password: true,
  })
  .extend({
    newPassword: z.string().trim().nonempty({
      message: 'A nova senha é obrigatória.',
    }),
    newPasswordConfirmation: z.string().trim().nonempty({
      message: 'A confirmação da nova senha é obrigatória.',
    }),
  })
  .refine((data) => data.password !== data.newPassword, {
    message: 'A nova senha não pode ser igual a antiga.',
    path: ['newPassword'],
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: 'As senhas não convergem.',
    path: ['newPasswordConfirmation'],
  });
export type UpdatePasswordDto = z.infer<typeof updatePasswordSchema>;

export interface UserProduct {
  id: number;
  name: string;
  userId: number;
  productCode: string;
  userRole: UserRole;
}

export interface Groups {
  owner: UserProduct[];
  coproducer: UserProduct[];
  affiliated: UserProduct[];
  referrer: UserProduct[];
}

export interface AuthenticatedUser {
  id: number;
  name: string;
  email: string;
  photo?: string;
  groups: Groups;
}

export type UserRole = 'OWNER' | 'COPRODUCER' | 'AFFILIATED' | 'REFERRER';
export const UserRoles: Record<UserRole, UserRole> = {
  OWNER: 'OWNER',
  COPRODUCER: 'COPRODUCER',
  AFFILIATED: 'AFFILIATED',
  REFERRER: 'REFERRER',
};
export enum ParsedUserRole {
  OWNER = 'Dono',
  COPRODUCER = 'Coprodutor',
  AFFILIATED = 'Afiliado',
  REFERRER = 'Indicação',
}
